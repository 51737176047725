/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import { Helmet } from "react-helmet"
// import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import RecipeIcon from "@material-ui/icons/Restaurant"
import AboutIcon from "@material-ui/icons/Info"
import IconButton from "@material-ui/core/IconButton"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import FacebookIcon from "@material-ui/icons/Facebook"
import PinterestIcon from "@material-ui/icons/Pinterest"
import LocalMallIcon from "@material-ui/icons/LocalMall"
import CalculateIcon from "@material-ui/icons/Iso"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CloseIcon from "@material-ui/icons/Close"

import _ from "lodash"

import ListItemText from "@material-ui/core/ListItemText"
import theme from "../../src/theme"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  sidebar: {
    width: 250,
  },
  sidebarPaper: {
    backgroundColor: theme.palette.primary.main,
  },
  socialCircle: {
    color: "white",
    borderRadius: "50%",
    width: 32,
    height: 32,
    marginLeft: theme.spacing(1),
  },
  mainListItemIcon: {
    color: "white",
  },
  mainListItemText: {
    marginTop: 0,
    color: "white",
  },
  listItemText: {
    marginTop: 0,
    marginLeft: theme.spacing(3),
    color: "white",
  },
  subMenu: {
    marginTop: 0,
  },
  subMenuHeaderLink: {
    marginTop: 0,
    fontWeight: "bold",
    color: "white",
  },
  backMenu: {
    backgroundColor: theme.palette.primary.main,
  },
  backMenuLink: {
    marginTop: 0,
    marginLeft: "4px",
    fontSize: "0.8rem",
    color: "white",
  },
  menuParentArrow: {
    fontSize: "1rem",
    color: "white",
  },
})

const Layout = ({ children }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulMenu {
        edges {
          node {
            menuItems {
              linkText
              link
              icon
              contentfulchildren {
                ... on ContentfulMenuItem {
                  id
                  linkText
                  link
                }
              }
            }
          }
        }
      }
    }
  `)
  const menu = data.allContentfulMenu.edges[0].node

  const [state, setState] = React.useState({
    sidebarOpen: false,
  })

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, sidebarOpen: open })
  }

  const [subDrawerOpen, setOpenSubDrawer] = React.useState()
  const handleOpenSubDrawer = (subDrawerName) => (event) => {
    toggleDrawer(false, event)
    setOpenSubDrawer(subDrawerName)
  }

  const handleSubMenuClose = () => {
    setOpenSubDrawer(null)
  }

  const handleBackMenuClick = (event) => {
    toggleDrawer(true)(event)
    setOpenSubDrawer(null)
  }

  const handleCloseMenuClick = (event) => {
    toggleDrawer(false)(event)
    setOpenSubDrawer(null)
  }

  const icons = {
    ListItemIcon: ListItemIcon,
    MenuBookIcon: MenuBookIcon,
    RecipeIcon: RecipeIcon,
    AboutIcon: AboutIcon,
    IconButton: IconButton,
    InstagramIcon: InstagramIcon,
    YouTubeIcon: YouTubeIcon,
    FacebookIcon: FacebookIcon,
    PinterestIcon: PinterestIcon,
    LocalMallIcon: LocalMallIcon,
    CalculateIcon: CalculateIcon,
    ArrowForwardIcon: ArrowForwardIcon,
  }

  return (
    <>
      <React.Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Helmet>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          {/* <CssBaseline /> */}
          <Header
            siteTitle={data.site.siteMetadata.title}
            toggleDrawer={toggleDrawer}
            menu={menu}
          />
          <Drawer
            open={state.sidebarOpen}
            onClose={toggleDrawer(false)}
            anchor="left"
            PaperProps={{ className: classes.sidebarPaper }}
          >
            <div
              role="none"
              className={classes.sidebar}
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem
                  button
                  className={classes.backMenu}
                  onClick={handleCloseMenuClick}
                >
                  <CloseIcon
                    fontSize="small"
                    className={classes.mainListItemIcon}
                  />
                  <ListItemText
                    primary="Close Menu"
                    primaryTypographyProps={{ className: classes.backMenuLink }}
                  />
                </ListItem>
                {_.map(menu.menuItems, (node) => {
                  const IconComponent = icons[node.icon] || ArrowForwardIcon

                  if ((node.contentfulchildren || []).length > 0) {
                    return (
                      <ListItem
                        button
                        key={node.link}
                        onClick={handleOpenSubDrawer(node.linkText)}
                      >
                        <ListItemIcon className={classes.mainListItemIcon}>
                          <IconComponent />
                        </ListItemIcon>
                        <ListItemText
                          primary={node.linkText}
                          primaryTypographyProps={{
                            className: classes.mainListItemText,
                          }}
                        />
                        <ArrowForwardIosIcon
                          fontSize="small"
                          className={classes.menuParentArrow}
                        />
                      </ListItem>
                    )
                  } else {
                    return (
                      <ListItem
                        button
                        key={node.link}
                        component="a"
                        href={`${node.link}`}
                      >
                        <ListItemIcon className={classes.mainListItemIcon}>
                          <IconComponent />
                        </ListItemIcon>
                        <ListItemText
                          primary={node.linkText}
                          primaryTypographyProps={{
                            className: classes.mainListItemText,
                          }}
                        />
                      </ListItem>
                    )
                  }
                })}
              </List>
            </div>
          </Drawer>
          {menu.menuItems
            .filter(
              (menuItem) => (menuItem.contentfulchildren || []).length > 0
            )
            .map((menuItem) => {
              const IconComponent = icons[menuItem.icon] || ArrowForwardIcon

              return (
                <Drawer
                  key={menuItem.linkText}
                  open={subDrawerOpen === menuItem.linkText}
                  anchor="left"
                  onClose={handleSubMenuClose}
                  className={classes.subMenu}
                  PaperProps={{ className: classes.sidebarPaper }}
                  ModalProps={{ onBackdropClick: handleSubMenuClose }}
                >
                  <div role="none" className={classes.sidebar}>
                    <List>
                      <ListItem
                        button
                        className={classes.backMenu}
                        onClick={handleBackMenuClick}
                      >
                        <ArrowBackIcon
                          fontSize="small"
                          className={classes.mainListItemIcon}
                        />
                        <ListItemText
                          primary="Back to Main Menu"
                          primaryTypographyProps={{
                            className: classes.backMenuLink,
                          }}
                        />
                      </ListItem>
                      <ListItem button component="a" href={menuItem.link}>
                        <ListItemIcon className={classes.mainListItemIcon}>
                          <IconComponent />
                        </ListItemIcon>
                        <ListItemText
                          primary={menuItem.linkText}
                          primaryTypographyProps={{
                            className: classes.subMenuHeaderLink,
                          }}
                        />
                      </ListItem>
                      {_.map(menuItem.contentfulchildren, (node) => {
                        return (
                          <ListItem
                            button
                            key={node.link}
                            component="a"
                            href={`${node.link}`}
                          >
                            <ListItemText
                              primary={node.linkText}
                              primaryTypographyProps={{
                                className: classes.listItemText,
                              }}
                            />
                          </ListItem>
                        )
                      })}
                    </List>
                  </div>
                </Drawer>
              )
            })}
          <main>{children}</main>
          <footer>
            <Box py={8}>
              <Typography variant="body1" align="center">
                © {new Date().getFullYear()}, Dessertisans
                <IconButton
                  aria-label="Dessertisans YouTube"
                  className={classes.socialCircle}
                  style={{ background: "#FF0000" }}
                  href="https://www.youtube.com/c/dessertisans"
                  target="_blank"
                >
                  <YouTubeIcon />
                </IconButton>
                <IconButton
                  aria-label="Dessertisans Instagram"
                  className={classes.socialCircle}
                  style={{ background: "#DD326F" }}
                  href="https://www.instagram.com/dessertisans/"
                  target="_blank"
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  aria-label="Dessertisans Facebook"
                  className={classes.socialCircle}
                  style={{ background: "#1877F2" }}
                  href="https://www.facebook.com/Dessertisans"
                  target="_blank"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  aria-label="Dessertisans Pinterest"
                  className={classes.socialCircle}
                  style={{ background: "#E60023" }}
                  href="https://www.pinterest.com.au/dessertisans/"
                  target="_blank"
                >
                  <PinterestIcon />
                </IconButton>
              </Typography>
            </Box>
          </footer>
        </ThemeProvider>
      </React.Fragment>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
