// import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Typography from "@material-ui/core/Typography"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import MenuIcon from "@material-ui/icons/Menu"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Link from "@material-ui/core/Link"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    color: "#FFF",
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    height: "80px",
  },
  mainContainer: {
    height: "100%",
  },
  siteTitle: {
    [theme.breakpoints.down("md")]: {
      "&:only-child": {
        margin: "0 auto",
        flex: 0,
      },
    },
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexLogo: {
    minWidth: 45,
    margin: 0,
  },
  menuItem: {
    fontWeight: "bold",
  },
  menuButton: {},
  menuIcon: {
    fontSize: "0.8rem",
    marginRight: "5px",
  },
  menuButtonText: {
    marginLeft: 0,
    fontSize: "0.7rem",
    fontWeight: "bold",
    lineHeight: 2,
  },
  menuButtonIcon: {
    verticalAlign: "middle",
    paddingBottom: "5px",
  },
  menuPaper: {
    backgroundColor: theme.palette.primary.main,
  },
  subMenuLink: {
    color: "white",
  },
  logoHeading: {
    marginTop: 6,
    marginLeft: 4,
    fontSize: "1.1rem",
    lineHeight: "1.7rem",
    fontFamily: "Marcellus",
    "&::first-letter": {
      fontSize: "1.5rem",
    },
  },
  menuItemLink: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  mobileMenuBox: {
    flex: 1,
  },
}))

const Header = ({ siteTitle, toggleDrawer, menu }) => {
  const [anchorEl, setAnchorEl] = React.useState({})
  const [open, setOpen] = React.useState({})

  const handleClose = (menuItem) => {
    setOpen({
      ...open,
      [menuItem]: false,
    })
  }

  const handlePopoverOpen = (event, menuItem) => {
    setAnchorEl({
      ...anchorEl,
      [menuItem]: event.currentTarget,
    })
    handleOpen(menuItem)
  }

  const handleOpen = (menuItem) => {
    setOpen({
      ...open,
      [menuItem]: true,
    })
  }

  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logoSmall: file(
        absolutePath: {
          regex: "/src/images/dessertisans_logo_medium_transparent.png/"
        }
      ) {
        childImageSharp {
          resize(width: 45, height: 45) {
            src
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="lg" className={classes.mainContainer}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Hidden mdUp>
              <Box className={classes.mobileMenuBox}>
                <Button
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  variant="outlined"
                  onClick={toggleDrawer(true)}
                  size="small"
                  className={classes.menuButton}
                >
                  <MenuIcon className={classes.menuIcon} />
                  <Typography
                    variant="button"
                    color="primary.contrast"
                    className={classes.menuButtonText}
                  >
                    Menu
                  </Typography>
                </Button>
              </Box>
            </Hidden>
            <Box className={`${classes.siteTitle} ${classes.mobileMenuBox}`}>
              <Link
                href="/"
                color="inherit"
                underline="none"
                className={classes.flexContainer}
              >
                <img
                  src={data.logoSmall.childImageSharp.resize.src}
                  className={classes.flexLogo}
                  data-pin-nopin="true"
                  alt={siteTitle}
                  width={45}
                  height={45}
                ></img>
                <Typography component="h1" className={classes.logoHeading}>
                  DESSERTISANS
                </Typography>
              </Link>
            </Box>
            <Hidden mdUp>
              <Box className={classes.mobileMenuBox}></Box>
            </Hidden>
            <Hidden smDown>
              {_.map(menu.menuItems, (node, index) => {
                return (
                  <>
                    <Box
                      key={node.link}
                      mr={
                        index + 1 === menu.menuItems.length
                          ? 0
                          : (node.contentfulchildren || []).length > 0
                          ? 3
                          : 4
                      }
                    >
                      <Typography variant="button" className={classes.menuItem}>
                        <Link
                          className={classes.menuItemLink}
                          href={node.link}
                          color="inherit"
                          onMouseOver={(event) =>
                            handlePopoverOpen(event, node.linkText)
                          }
                          onMouseLeave={() => handleClose(node.linkText)}
                        >
                          {node.linkText}
                          {(node.contentfulchildren || []).length > 0 && (
                            <KeyboardArrowDownIcon
                              className={classes.menuButtonIcon}
                            />
                          )}
                        </Link>
                      </Typography>
                    </Box>
                    {(node.contentfulchildren || []).length > 0 && (
                      <Menu
                        key={`${node.link}-menu`}
                        className={classes.popover}
                        classes={{
                          paper: classes.popoverContent,
                        }}
                        open={open[node.linkText]}
                        anchorEl={anchorEl[node.linkText]}
                        onClose={() => handleClose(node.linkText)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: -12,
                          horizontal: "left",
                        }}
                        getContentAnchorEl={null}
                        PaperProps={{
                          onMouseEnter: () => handleOpen(node.linkText),
                          onMouseLeave: () => handleClose(node.linkText),
                          className: classes.menuPaper,
                        }}
                      >
                        {_.map(
                          node.contentfulchildren,
                          (subMenuItem, index) => {
                            return (
                              <Link
                                key={subMenuItem.link}
                                href={subMenuItem.link}
                                className={classes.subMenuLink}
                              >
                                <MenuItem>{subMenuItem.linkText}</MenuItem>
                              </Link>
                            )
                          }
                        )}
                      </Menu>
                    )}
                  </>
                )
              })}
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
