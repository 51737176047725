import { red } from '@material-ui/core/colors';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import _ from 'lodash'

// A custom theme for this app
// https://material-ui.com/customization/typography/
// https://material-ui.com/customization/spacing/
// https://material-ui.com/customization/default-theme/


// Establishing "rhythm" with margins based on line height as suggested in: https://betterwebtype.com/articles/2018/10/15/rhythm-in-web-typography/#vertical-rhythm
// Except rhythm was contested with: https://zellwk.com/blog/wrong-about-vertical-rhythm/ so using it more loosely.
const lineHeight = 1.45;
const spacing = (lineHeightMultiplier, marginTopMultiplier, marginBottomMultiplier) => {
  return {
    lineHeight: `${lineHeight * lineHeightMultiplier}rem`,
    marginTop: `${lineHeight * marginTopMultiplier}rem`,
    marginBottom: `${lineHeight * marginBottomMultiplier}rem`
  };
};

// Using the modular scale for headers as suggested in: https://css-tricks.com/typography-for-developers/#adjusting-font-sizing
// Could use a smaller ratio for smaller screens and a larger ratio for bigger screens
// h5 - h6 probably won't be used
const ratio = 1.43
const modular = multiplier => {
  return `${Math.pow(ratio, multiplier)}rem`
}

const theme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: 'Lato',
    fontSize: 14,
    h1: { fontSize: modular(3), fontWeight: 400 },
    h2: { fontSize: modular(2), fontWeight: 500 },
    h3: { fontSize: modular(1), fontWeight: 500 },
    h4: { fontSize: modular(0.5), fontWeight: 500 },
    h5: { fontSize: modular(0), fontWeight: 600 },
    h6: { fontSize: modular(0) },
  },
  palette: {
    primary: {
      light: '#e3bc9a',
      main: '#BE8F65',
      dark: '#9A693E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#eac99e',
      main: '#e5bc86',
      dark: '#a0835d',
      contrastText: '#000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiCardActionArea: {
      focusHighlight: {
        backgroundColor: "#efefef",
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1.5rem"
      },
      h1: _.merge(
        spacing(1.5, 2, 1),
        { '@media (min-width:600px)': spacing(2.5, 2.5, 1) }
      ),
      h2: _.merge(
        spacing(1.5, 1.5, 1),
        { '@media (min-width:600px)': spacing(1.5, 2, 1) }
      ),
      h3: _.merge(
        spacing(1, 1.25, 0.75),
        { '@media (min-width:600px)': spacing(1, 1.5, 0.75) }
      ),
      h4: _.merge(spacing(1, 1.25, 0.75), { textTransform: 'uppercase' }),
      h5: spacing(1, 1, 1),
      h6: spacing(1, 1, 1),
      body1: spacing(1, 0.75, 0)
    }
  }
}));

export default theme;
